import React from "react";
import { Link } from "react-router-dom";
import { update_page_meta } from "../actions/page/header";
import { connect } from "react-redux";

const PageNotFound = ({ update_page_meta }) => {
    update_page_meta({
        title: "Page Not Found | Aquarius HR Consultants",
        description: ""
    })
    return (
        <>
            <section class="error-section centred">
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-lg-10 col-md-12 col-sm-12 offset-lg-1 content-column">
                            <div class="error-content">
                                <figure class="image-box"><img src={require("../assets/images/icons/error-img.png")} alt="404 Not Found" /></figure>
                                <h2>Sorry this page isn’t available</h2>
                                <p>We're not being able to find the page you're looking for</p>
                                <Link to="/" class="theme-btn-two">Go Back to Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default connect(null, { update_page_meta })(PageNotFound);