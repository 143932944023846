import React, { useEffect } from "react";
import { connect } from "react-redux";
import MenuContent from "./MenuContent";
import { addressHtml, email, mailto, officeaddressHtml, phone, telLink } from "../constants/contact";
import { facebook, instagram, linkedin, twitter, youtube } from "../constants/socialmedia";
import { Link, useLocation } from "react-router-dom";

const MobileMenu = ({}) => {
    const closeMenu = (e) => {
        let body = document.body;
        body.classList.remove('mobile-menu-visible');
    }
    let location = useLocation();
    // When url changes, hide mobile menu if it is visible
    useEffect(() => {
        let body = document.body;
        body.classList.remove('mobile-menu-visible');
    }, [location.pathname]);
    return (
        <div className="mobile-menu">
            <div className="menu-backdrop"></div>
            <div className="close-btn" onClick={closeMenu}><i className="fas fa-times"></i></div>
            <nav className="menu-box">
                <div className="nav-logo bg-white" style={{padding: "30px 25px 30px"}}><Link to="/"><img src={require("../assets/images/2.webp")} alt="Aquarius Consultants Logo" title="" /></Link></div>
                {/* <div className="d-flex mb-2" style={{justifyContent: "center"}}>
                    <Link to="/start-hiring/" className="theme-btn-two">Start Hiring</Link>
                    <Link to="/apply-for-job/" className="ms-2 theme-btn-two">Apply for Job</Link>
                </div> */}
                <div className="menu-outer">
                    <MenuContent mobile={true} />
                </div>
                <div className="contact-info">
                    <h4>Contact Info</h4>
                    <ul>
                        <li><h6 style={{color: "white"}}>Gurugram</h6>{officeaddressHtml}</li>
                        <li className="mt-3"><h6 style={{color: "white"}}>Registered Office</h6>{addressHtml}</li>
                        <li><a href={telLink}>{phone}</a></li>
                        <li><a href={mailto}>{email}</a></li>
                    </ul>
                </div>
                <div className="social-links">
                    <ul className="clearfix">
                        <li><a href={linkedin} target="_blank"><span className="fab fa-linkedin"></span></a></li>
                        {/* <li><a href={facebook} target="_blank"><span className="fab fa-facebook-f"></span></a></li>
                        <li><a href={twitter} target="_blank"><span className="fab fa-twitter"></span></a></li>
                        <li><a href={youtube} target="_blank"><span className="fab fa-youtube"></span></a></li>
                        <li><a href={instagram} target="_blank"><span className="fab fa-instagram"></span></a></li> */}
                    </ul>
                </div>
            </nav>
        </div>
    )
}

export default connect(null, {})(MobileMenu);