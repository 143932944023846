import React from "react";
import { connect } from "react-redux";
import { email, mailto, phone, telLink } from "../constants/contact";
import { facebook, instagram, linkedin, twitter, youtube } from "../constants/socialmedia";

const TopHeader = ({}) => {
    return (
        <div className="header-top">
                <div className="top-inner">
                    <ul className="left-info">
                        <li>
                            <i className="flaticon-phone-call"></i>
                            <p><a href={telLink}>{phone}</a></p>
                        </li>
                        <li>
                            <i className="flaticon-email"></i>
                            <p><a href={mailto}>{email}</a></p>
                        </li>
                    </ul>
                    {/* <div className="location-box">
                        <div className="location-carousel owl-carousel owl-theme owl-dots-none">
                            <p><span>Recent:</span> Business Development Manager, San Fransisco, CA.</p>
                            <p><span>Recent:</span> Business Development Manager, Landon, UK.</p>
                            <p><span>Recent:</span> Business Development Manager, New York.</p>
                        </div>
                        <div className="apply-btn"><a href="index.html">Apply Online<i className="flaticon-arrow-pointing-to-right"></i></a></div>
                    </div> */}
                    <div className="right-info">
                        {/* <ul className="list">
                            <li><a href="index.html">About Us</a></li>
                            <li><a href="index.html">Locations</a></li>
                            <li><a href="index.html">Resources</a></li>
                        </ul> */}
                        <ul className="social-links">
                            <li><a href={linkedin} target="_blank"><i className="fab fa-linkedin"></i></a></li>
                            {/* <li><a href={facebook} target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                            <li><a href={twitter} target="_blank"><i className="fab fa-twitter"></i></a></li>
                            <li><a href={youtube} target="_blank"><i className="fab fa-youtube"></i></a></li>
                            <li><a href={instagram} target="_blank"><i className="fab fa-instagram"></i></a></li> */}
                        </ul>
                    </div>
                </div>
            </div>
    )
}

export default connect(null, {})(TopHeader);