import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import clients from '../data/clients.json';
import { Link } from "react-router-dom";

const ClientSliderWithCTA = ({}) => {
    return (
        <section className="clients-style-two" style={{backgroundImage: `url(${require('../assets/images/background/clients-1.jpg')})`}}>
            <div className="auto-container">
                <div className="title-inner centred">
                    <h2>Are you looking for exceptional executives to drive your organization forward?</h2>
                    <div className="btn-box">
                        <Link to="/contact/" className="btn-one">Contact Us</Link>
                    </div>
                </div>
                <Swiper
                    className="clients-carousel"
                    modules={[Autoplay]}
                    loop={true}
                    speed={3000}
                    freeMode={true}
                    autoplay={{
                        delay: 0,
                        disableOnInteraction: false
                    }}
                    spaceBetween={0}
                    slidesPerView={1}
                    breakpoints={{
                        0: {
                            slidesPerView: 1
                        },
                        480: {
                            slidesPerView: 2
                        },
                        600: {
                            slidesPerView: 3
                        },
                        800: {
                            slidesPerView: 4
                        },
                        1200: {
                            slidesPerView: 6
                        }
                    }}
                    >
                        {
                            clients.map(client => {
                                return (
                                    <SwiperSlide key={client.id}>
                                        <figure className="clients-logo-box">
                                            <img src={require(`../assets/images/clients/${client?.uri}`)} style={{height: '80px', width: "135px"}} alt={client?.name} />
                                        </figure>
                                    </SwiperSlide>
                                )
                            })
                        }
                </Swiper>
            </div>
        </section>
    )
}

export default ClientSliderWithCTA;