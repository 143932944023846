import React from "react";
import PageTitle from "../../components/PageTitle";
import ClientSliderWithCTA from "../../components/ClientSliderWithCTA";
import TestimonialSlider from "../../components/testimonials/TestimonialSlider";
import services from "../../data/services.json";
import ListWidget from "../../components/services/ListWidget";
import { update_page_meta } from "../../actions/page/header";
import { connect } from "react-redux";
const service = services.find(item => item.id === 1);

const ExecutiveSearchService = ({ update_page_meta }) => {
    update_page_meta({
        title: `${service.title} | Aquarius HR Consultants`,
        description: "Identify and hire top-level executives and senior management professionals"
    })
    return (
        <>
            <PageTitle title={service.title} description="Identify and hire top-level executives and senior management professionals" breadcrumbs={[{title: "Home", url: "/"}, {title: "Services", url: "/services/"}, {title: "Executive Search Service"}]} />
        
            <section class="service-details">
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-lg-8 col-md-12 col-sm-12 content-side">
                            <div class="service-details-content">
                                <div class="content-one">
                                    <div class="sec-title">
                                        {/* <span class="top-title">temporary Staffing</span> */}
                                        <h2>{service.title}</h2>
                                    </div>
                                    {/* <figure class="image-box"><img src="assets/images/service/details-1.jpg" alt="" /></figure> */}
                                    <div class="text">
                                        <p>
                                            Working since 2010 in executive search, our firm has developed deep market knowledge and expertise in conducting specialist searches across various functions and levels within organizations. We have expertise in CEO, CXO & mid senior roles as well as specialized professionals across different functional areas.
                                        </p>
                                        <p>
                                            Our extensive experience in the field of executive search has allowed us to gain valuable insights into the dynamics of the market, industry trends, and the unique talent requirements of different sectors. We have built a strong network of contacts, both within organizations and across industries, which enables us to tap into a wide pool of talented individuals for our clients.
                                        </p>
                                        <p>
                                        Our deep market knowledge and intuition are the result of years of hands-on experience in navigating the executive search landscape. We have successfully placed executives and specialists in a variety of roles and industries, giving us a comprehensive understanding of the skills, qualifications, and cultural fit required for different positions.
                                        </p>
                                        <p>
                                        By leveraging our market knowledge and intuition, we can provide tailored and targeted search services to our clients. We understand the nuances and intricacies of each function and level within organizations, allowing us to identify candidates who possess the specific expertise and capabilities needed to excel in their roles.
                                        </p>
                                        <p>
                                        Our track record of success and the relationships we have built with both clients and candidates are a testament to our deep market knowledge and experience. We take pride in our ability to deliver exceptional results by matching the right talent with the right organizations.
                                        </p>
                                        <p>
                                        Whether our clients are seeking to fill senior executive positions or specialized roles across functions, our expertise and market knowledge enable us to provide insightful recommendations, thorough assessments, and a seamless search experience. We are committed to partnering with organizations to meet their talent acquisition needs and contribute to their long-term success.
                                        </p>
                                    </div>
                                </div>
                                <div class="content-four">
                                    <div class="upper-box">
                                        <h3>Our Advantages</h3>
                                    </div>
                                    <div class="inner-box">
                                        <div class="row clearfix">
                                            <div class="col-lg-6 col-md-6 col-sm-12 column">
                                                <ul class="list-item clearfix">
                                                    <li>
                                                        <h5>Extensive Research and Network</h5>
                                                        <div class="more-content">
                                                            <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                            <div class="text">
                                                                <p>We have access to vast networks, databases, and industry contacts, enabling us to reach out to 
                                                                    both active and passive candidates who may not be actively seeking new opportunities.</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <h5>Confidentiality</h5>
                                                        <div class="more-content">
                                                        <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                            <div class="text">
                                                                <p>We understand when recruiting for senior-level positions, confidentiality is of utmost importance. We ensure strict confidentiality throughout the process, maintaining the privacy and interests of all parties.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <h5>Customized Approach</h5>
                                                        <div class="more-content">
                                                            <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                            <div class="text">
                                                                <p>
                                                                Our Executive search services are tailored to the unique requirements of each organization. We work closely with the client to understand their specific needs, organizational culture, and strategic objectives and develop a detailed job specification and candidate profile to ensure a precise fit between the candidate and the organization's requirements.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <h5>Targeted Candidate Assessment</h5>
                                                        <div class="more-content">
                                                            <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                            <div class="text">
                                                                <p>
                                                                We employ rigorous assessment methods to evaluate candidates' qualifications, skills, experience, and cultural fit. We conduct in-depth interviews, comprehensive reference checks, and may utilize assessments, psychometric tests, or simulations to assess the candidates' suitability for the executive role.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 column">
                                                <ul class="list-item clearfix">
                                                    <li>
                                                        <h5>Access to Passive Candidates</h5>
                                                        <div class="more-content">
                                                            <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                            <div class="text">
                                                                <p>
                                                                A significant advantage is the ability to target passive candidates who are not actively looking for job opportunities. These individuals are often high-performing executives who are not actively searching but may be open to the right opportunity. We have the expertise and resources to engage with and attract these passive candidates.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <h5>Industry Expertise</h5>
                                                        <div class="more-content">
                                                            <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                            <div class="text">
                                                                <p>
                                                                We specialize in specific industries or sectors, allowing us to develop deep industry knowledge and expertise. This enables us to understand the unique challenges, trends, and talent requirements within specific industries and source candidates with relevant industry experience.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <h5>Time and Cost Efficiency</h5>
                                                        <div class="more-content">
                                                            <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                            <div class="text">
                                                                <p>
                                                                Executive searches can be time-consuming and resource-intensive for organizations. By outsourcing the search process to us, organizations can save time and effort. We have dedicated teams and streamlined processes in place, allowing for efficient candidate identification, assessment, and selection. This ultimately reduces the time-to-hire and minimizes the overall cost associated with executive recruitment.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <h5>Quality and Fit</h5>
                                                        <div class="more-content">
                                                            <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                            <div class="text">
                                                                <p>
                                                                We are focused on finding candidates who not only possess the required skills and experience but also fit well with the organization's culture, values, and long-term strategic goals. We conduct thorough evaluations and present a shortlist of highly qualified candidates to the hiring organization, ensuring that only the best-suited candidates are considered for the executive position.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                            <div class="service-sidebar" style={{overflow: "auto"}}>
                                <div class="sidebar-widget categories-widget" style={{position: "sticky", top: 0}}>
                                    <ListWidget activeId={1} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <TestimonialSlider />
            <ClientSliderWithCTA />
        </>
    )
}

export default connect(null, { update_page_meta })(ExecutiveSearchService);