import React from "react";
import { connect } from "react-redux";
import PageTitle from "../components/PageTitle";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { team } from "../data/team";
import { update_page_meta } from "../actions/page/header";

const ManagementTeam = ({ update_page_meta }) => {
    update_page_meta({
        title: "Meet Our Team | Aquarius HR Consultants",
        description: "Get to know the exceptional management team behind Aquarius HR Consultants, a renowned executive search firm. Our experienced leaders bring a wealth of knowledge, expertise, and industry insights to guide our executive search services. Meet the individuals who drive our success and ensure that we deliver exceptional results for our clients. With their strategic vision and deep understanding of talent acquisition, our management team is dedicated to finding the right executives who will lead your organization to new heights. Discover the powerhouse behind Aquarius HR Consultants and how their leadership can transform your executive search experience."
    })
    return (
        <>
            <PageTitle title="Meet Our Team" description="" breadcrumbs={[{title: "Home", url: "/"}, {title: "About", url: "/about/"}, {title: "Meet Our Team"}]} />

            <section className="team-style-three">
                <div className="auto-container">
                    <div className="sec-title centred">
                        <span className="top-title">Our leadership Team</span>
                        <h2>Board of Directors & Senior Executives</h2>
                        <p>Our accomplished leaders bring a wealth of expertise and experience to our organization</p>
                    </div>
                    <Swiper
                        className="three-item-carousel"
                        modules={[Autoplay]}
                        loop={true}
                        autoplay={{
                            delay: 500
                        }}
                        spaceBetween={30}
                        slidesPerView={1}
                        breakpoints={{
                            0: {
                                slidesPerView: 1
                            },
                            480: {
                                slidesPerView: 1
                            },
                            600: {
                                slidesPerView: 2
                            },
                            800: {
                                slidesPerView: 2
                            },
                            1024: {
                                slidesPerView: 3
                            }
                        }}
                        >
                        {
                            team.leadership.map(item => {
                                return (
                                    <SwiperSlide key={item.id}>
                                    <div className="team-block-one">
                                        <div className="inner-box">
                                            <figure className="image-box">
                                                <img src={require(`../assets/images/team/${item.imgUrl}`)} alt={item.name} />
                                                {/* <span className="singature">{item.name}</span> */}
                                                {/* <div className="share-box">
                                                    <p>Connect</p>
                                                    <ul className="social-links clearfix">
                                                        <li><a href={item.linkedin} target="_blank"><i className="fab fa-linkedin"></i></a></li>
                                                        <li><a href={item.facebook} target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                                        <li><a href={item.twitter} target="_blank"><i className="fab fa-twitter"></i></a></li>
                                                    </ul>
                                                </div> */}
                                            </figure>
                                            <div className="lower-content">
                                                <h3>{item.name}</h3>
                                                <span className="designation" style={{color: "rgb(26,20,85)"}}>{item.designation}</span>
                                                {item.profile}
                                            </div>
                                        </div>
                                    </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
            </section>

            {/* <section className="team-style-four bg-color-2">
                <div className="auto-container">
                    <div className="sec-title centred">
                        <span className="top-title">Our Executive Team</span>
                        <h2>Team Behind Our Successful Service</h2>
                        <p>Long established fact that a reader will be distracted by the <br />readable content of a page.</p>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-3 col-md-6 col-sm-12 team-block">
                            <div className="team-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="inner-box">
                                    <figure className="image-box">
                                        <img src="assets/images/team/team-11.jpg" alt="">
                                        <span className="singature">Our Champ</span>
                                        <div className="share-box">
                                            <p><i className="fas fa-share-alt"></i>Share</p>
                                            <ul className="social-links clearfix">
                                                <li><a href="index.html"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-google-plus-g"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-youtube"></i></a></li>
                                            </ul>
                                        </div>
                                    </figure>
                                    <div className="lower-content">
                                        <h3><a href="index.html">Garrett Barnie</a></h3>
                                        <span className="designation">Founder</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 team-block">
                            <div className="team-block-one wow fadeInUp animated animated" data-wow-delay="200ms" data-wow-duration="1500ms">
                                <div className="inner-box">
                                    <figure className="image-box">
                                        <img src="assets/images/team/team-12.jpg" alt="">
                                        <span className="singature">Our Champ</span>
                                        <div className="share-box">
                                            <p><i className="fas fa-share-alt"></i>Share</p>
                                            <ul className="social-links clearfix">
                                                <li><a href="index.html"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-google-plus-g"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-youtube"></i></a></li>
                                            </ul>
                                        </div>
                                    </figure>
                                    <div className="lower-content">
                                        <h3><a href="index.html">Joel Lou</a></h3>
                                        <span className="designation">Consultant</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 team-block">
                            <div className="team-block-one wow fadeInUp animated animated" data-wow-delay="400ms" data-wow-duration="1500ms">
                                <div className="inner-box">
                                    <figure className="image-box">
                                        <img src="assets/images/team/team-13.jpg" alt="">
                                        <span className="singature">Our Champ</span>
                                        <div className="share-box">
                                            <p><i className="fas fa-share-alt"></i>Share</p>
                                            <ul className="social-links clearfix">
                                                <li><a href="index.html"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-google-plus-g"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-youtube"></i></a></li>
                                            </ul>
                                        </div>
                                    </figure>
                                    <div className="lower-content">
                                        <h3><a href="index.html">Garrett Barnie</a></h3>
                                        <span className="designation">Consultant</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 team-block">
                            <div className="team-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                                <div className="inner-box">
                                    <figure className="image-box">
                                        <img src="assets/images/team/team-14.jpg" alt="">
                                        <span className="singature">Our Champ</span>
                                        <div className="share-box">
                                            <p><i className="fas fa-share-alt"></i>Share</p>
                                            <ul className="social-links clearfix">
                                                <li><a href="index.html"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-google-plus-g"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-youtube"></i></a></li>
                                            </ul>
                                        </div>
                                    </figure>
                                    <div className="lower-content">
                                        <h3><a href="index.html">Joel Lou</a></h3>
                                        <span className="designation">Consultant</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 team-block">
                            <div className="team-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="inner-box">
                                    <figure className="image-box">
                                        <img src="assets/images/team/team-15.jpg" alt="">
                                        <span className="singature">Our Champ</span>
                                        <div className="share-box">
                                            <p><i className="fas fa-share-alt"></i>Share</p>
                                            <ul className="social-links clearfix">
                                                <li><a href="index.html"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-google-plus-g"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-youtube"></i></a></li>
                                            </ul>
                                        </div>
                                    </figure>
                                    <div className="lower-content">
                                        <h3><a href="index.html">Isaac Herman</a></h3>
                                        <span className="designation">Consultant</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 team-block">
                            <div className="team-block-one wow fadeInUp animated animated" data-wow-delay="200ms" data-wow-duration="1500ms">
                                <div className="inner-box">
                                    <figure className="image-box">
                                        <img src="assets/images/team/team-16.jpg" alt="">
                                        <span className="singature">Our Champ</span>
                                        <div className="share-box">
                                            <p><i className="fas fa-share-alt"></i>Share</p>
                                            <ul className="social-links clearfix">
                                                <li><a href="index.html"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-google-plus-g"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-youtube"></i></a></li>
                                            </ul>
                                        </div>
                                    </figure>
                                    <div className="lower-content">
                                        <h3><a href="index.html">Joel Lou</a></h3>
                                        <span className="designation">Consultant</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 team-block">
                            <div className="team-block-one wow fadeInUp animated animated" data-wow-delay="400ms" data-wow-duration="1500ms">
                                <div className="inner-box">
                                    <figure className="image-box">
                                        <img src="assets/images/team/team-17.jpg" alt="">
                                        <span className="singature">Our Champ</span>
                                        <div className="share-box">
                                            <p><i className="fas fa-share-alt"></i>Share</p>
                                            <ul className="social-links clearfix">
                                                <li><a href="index.html"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-google-plus-g"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-youtube"></i></a></li>
                                            </ul>
                                        </div>
                                    </figure>
                                    <div className="lower-content">
                                        <h3><a href="index.html">Garrett Barnie</a></h3>
                                        <span className="designation">Consultant</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 team-block">
                            <div className="team-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                                <div className="inner-box">
                                    <figure className="image-box">
                                        <img src="assets/images/team/team-18.jpg" alt="">
                                        <span className="singature">Our Champ</span>
                                        <div className="share-box">
                                            <p><i className="fas fa-share-alt"></i>Share</p>
                                            <ul className="social-links clearfix">
                                                <li><a href="index.html"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-google-plus-g"></i></a></li>
                                                <li><a href="index.html"><i className="fab fa-youtube"></i></a></li>
                                            </ul>
                                        </div>
                                    </figure>
                                    <div className="lower-content">
                                        <h3><a href="index.html">Joel Lou</a></h3>
                                        <span className="designation">Consultant</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    )
}

export default connect(null, { update_page_meta })(ManagementTeam);