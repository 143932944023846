import React from "react";
import industries from '../../data/industries.json';
import { Link } from "react-router-dom";


const IndustriesSection = ({}) => {
    let industries5 = industries.filter((item, index) => index <= 4)
    const handleClick = (e, index) => {
        let tabbtn = e.target.closest('.tab-btn');
        if (tabbtn.classList.contains('active-btn'))
            return;
        let tabbox = e.target.closest('.tabs-box');
        tabbox.querySelector('.active-btn').classList.remove('active-btn');
        tabbtn.classList.add('active-btn');
        let tabsContent = tabbox.querySelector('.tabs-content');
        let targetItem = tabsContent.children.item(index);
        if (targetItem.classList.contains('active-tab'))
            return;
        let rem = tabsContent.querySelector('.active-tab');
        rem.classList.remove('active-tab');
        targetItem.classList.add('active-tab');
    }
    const btns = industries5.map((item, index) => {
            return (
                <li key={index} className={`tab-btn ${index === 0 ? 'active-btn' : ''}`} data-index={index} onClick={(e) => {handleClick(e, index)}}>
                    {/* <figure className="icon-box">
                        <img src="assets/images/icons/icon-10.png" alt="" />
                    </figure> */}
                    <h3 style={{textTransform: "capitalize"}}>{item?.name}</h3>
                </li>
            )
    })
    const tabItems = industries5.map((item, index) => {
        return (
            <div key={index} className={`tab ${index === 0 ? 'active-tab' : ''}`}>
                <div className="inner-box">
                    <figure className="image-box"><img src={require(`../../assets/images/industries/${item.uri}`)} alt={item.name} /></figure>
                    <div className="content-box">
                        <div className="text">
                            <h2 style={{textTransform: "capitalize"}}>{item.name} Industry</h2>
                            {/* <span>26 Jobs in 10 Different Companies</span> */}
                        </div>
                        <div className="line"></div>
                        {/* <div className="link"><a href="index.html"><i className="flaticon-right-arrow-angle"></i></a></div> */}
                    </div>
                </div>
            </div>
        )
    })
    return (
        <section className="industries-section bg-color-1">
            <div className="pattern-layer" style={{backgroundImage: 'url("../../assets/images/shape/pattern-7.png")'}}></div>
            <div className="auto-container">
                <div className="tabs-box">
                    <div className="row clearfix">
                        <div className="col-xl-4 col-lg-12 col-sm-12 btn-column">
                            <div className="sec-title light">
                                <span className="top-title">Our Areas of Expertise</span>
                                <h2>Industries We Service</h2>
                            </div>
                            <div className="tab-btn-box">
                                <ul className="tab-btns tab-buttons clearfix">
                                    {btns}
                                </ul>
                                <Link to="/services/industries/" className="mt-3 theme-btn-two inverted">View All</Link>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-12 col-sm-12 content-column">
                            <div className="tabs-content">
                                {tabItems}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default IndustriesSection;