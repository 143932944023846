import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

const PageTitle = ({title, description, breadcrumbs}) => {
    return (
        <section className="page-title" style={{backgroundImage: `url(${require('../assets/images/background/banner-default.png')})`}}>
        {/* <section className="page-title"> */}
            <div className="pattern-layer" style={{backgroundImage: `url(${require('../assets/images/shape/pattern-35.png')})`}}></div>
            <div className="auto-container">
                <div className="content-box">
                    <div className="title-box centred">
                        <h1>{title}</h1>
                        <p>{description}</p>
                    </div>
                    <ul className="bread-crumb clearfix">
                        {
                            breadcrumbs.map((item, index) => {
                                if (breadcrumbs.length !== (index + 1))
                                    return <li key={index}><Link to={item.url}>{item.title}</Link></li>
                                return <li key={index}>{item.title}</li>
                            })
                        }
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default connect(null, {})(PageTitle);