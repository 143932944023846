import React from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import services from "../data/services.json";

const MenuContent = ({ mobile=false }) => {
    const handleClick = (e) => {
        if (!mobile)
            return;
        let el = e.target.closest('.dropdown');
        // console.log(el.querySelector('.dropdown-btn'));
        let d = el.querySelectorAll('.dropdown-btn')[el.querySelectorAll('.dropdown-btn').length - 1];
        d.classList.toggle('open');
        if (d.classList.contains('open'))
            el.querySelector('ul').style.display = 'block';
        else
            el.querySelector('ul').style.display = 'none';
    }
    let location = useLocation();
    return (
        <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
            <ul className="navigation clearfix">
                <li className={location.pathname === '/' ? 'current' : ''}><Link to="/">Home</Link></li>
                <li className={`dropdown ${["/about/", '/about/why-us/', '/about/management-team/'].indexOf(location.pathname) !== -1 ? 'current' : ''}`}><Link to="/about/">About</Link>
                    <ul>
                        <li><Link to="/about/">About Company</Link></li>
                        {/* <li><Link to="/about/why-us/">Why Us</Link></li> */}
                        <li><Link to="/about/management-team/">Management Team</Link></li>
                    </ul>
                    <div className="dropdown-btn" onClick={handleClick}><span className="fas fa-angle-down"></span></div>
                </li>
                <li className={`dropdown ${["/services/", '/services/industries/', '/services/functional-roles/'].indexOf(location.pathname) !== -1 ? 'current' : ''}`}>
                    <Link to="/services/">Services</Link>
                    <ul>
                        <li className="dropdown"><Link to="/services/">Our Services</Link>
                            <ul className={mobile ? 'ms-3' : ''}>
                                {
                                    services.map((service, index) => {
                                        return (
                                            <li key={index}><Link to={service.uri}>{service.title}</Link></li>
                                        )
                                    })
                                }
                            </ul>
                            <div className="dropdown-btn" onClick={handleClick}><span className="fas fa-angle-down"></span></div>
                        </li>
                        <li><Link to="/services/industries/">Industries We Service</Link></li>
                        <li><Link to="/services/functional-roles/">Functional Roles</Link></li>
                    </ul>
                    <div className="dropdown-btn" onClick={handleClick}><span className="fas fa-angle-down"></span></div>
                </li>    
                {mobile && <li className={location.pathname === '/contact/' ? 'current' : ''}><Link to="/contact/">Contact</Link></li>}  
            </ul>
        </div>
    )
}

export default connect(null, {})(MenuContent);