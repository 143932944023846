import React from "react";
import { connect } from "react-redux";
import PageTitle from "../components/PageTitle";
import { update_page_meta } from "../actions/page/header";

const Compliance = ({ update_page_meta }) => {
    update_page_meta({
        title: "Compliance | Aquarius HR Consultants",
        description: ""
    })
    return (
        <>
            <PageTitle title="Compliance" description="" breadcrumbs={[{title: 'Home', url: "/"}, {title: "Compliance"}]} />

            <section className="advantages-section">
            <div className="pattern-layer" style={{backgroundImage: `url(${require('../assets/images/shape/pattern-46.png')})`}}></div>
            <div className="auto-container">
                {/* <div className="sec-title centred">
                    <span className="top-title">Benefits of Eazy Recruitz</span>
                    <h2>Advantages of Working With Us</h2>
                    <p>Long established fact that a reader will be distracted by the <br />readable content of a page.</p>
                </div> */}
                <div className="four-item-carousel row">
                    <div className="col-md-3 col-sm-6 col-xs-12 single-item">
                        <div className="inner-box">
                            <h3>MGT7</h3>
                            {/* <p>Indignation and dislike men who beguiled demoralized.</p> */}
                            <a href={require('../assets/downloads/AQUARIUS_MGT7.pdf')} target="_blank"><i className="flaticon-right-arrow"></i>View / Download</a>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12 single-item">
                        <div className="inner-box">
                            <h3>UDYAM Registration</h3>
                            {/* <p>Business it will frequently occur pleasures repudiated.</p> */}
                            <a href={require('../assets/downloads/Udyam Registration Certificate_AHRSPL.pdf')} target="_blank"><i className="flaticon-right-arrow"></i>View / Download</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            
        </>
    )
}

export default connect(null, { update_page_meta })(Compliance);