import React from "react";
import { connect } from "react-redux";
import industries from '../../data/industries.json';
import { Link } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import TestimonialSlider from "../../components/testimonials/TestimonialSlider";
import ClientSliderWithCTA from "../../components/ClientSliderWithCTA";
import { update_page_meta } from "../../actions/page/header";

const Industries = ({ update_page_meta }) => {
    update_page_meta({
        title: "Industries We Service | Aquarius HR Consultants",
        description: "Aquarius HR Consultants caters to a wide range of industries, providing comprehensive HR solutions tailored to the unique needs of each sector. Our deep understanding of industry dynamics, coupled with our expertise in talent management, allows us to deliver customized HR strategies and services. Whether you operate in technology, healthcare, finance, manufacturing, or any other industry, our team of HR professionals is equipped to support your organization's human capital needs in top & middle level management. Partner with Aquarius HR Consultants to gain a competitive edge in your industry by attracting, developing, and retaining top talent."
    })
    return (
        <>
            <PageTitle title="Industries We Service" description="" breadcrumbs={[{title: "Home", url: "/"}, {title: "Services", url: "/services/"}, {title: "Industries We Service"}]} />
            <section className="news-section">
                <div className="auto-container">
                    {/* <div className="sec-title centred">
                        <span className="top-title">Industries</span>
                        <h2>Featured News and Updates</h2>
                        <p>Long established fact that a reader will be distracted by the <br />readable content of a page.</p>
                    </div> */}
                    <div className="row clearfix">
                        {
                            industries.map((item, index) => {
                                return (
                                    <div className="col-lg-4 col-md-6 col-sm-12 news-block gy-5" key={index}>
                                        <div className="project-block-two">
                                            <div className="inner-box">
                                                <figure className="image-box"><img src={require(`../../assets/images/industries/${item.uri}`)} alt="" /></figure>
                                                {/* <div className="content-box">
                                                    <div className="text">
                                                        <h3>{item.name}</h3>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className="content-box" style={{borderRadius: "0 0 10px 10px", background: "rgb(76,169,160)"}}>
                                                <div className="text">
                                                    <h3 style={{padding: "15px 40px 15px 30px", color: "white"}}>{item.name}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <TestimonialSlider />
            <ClientSliderWithCTA />
        </>
    )
}

export default connect(null, { update_page_meta })(Industries);