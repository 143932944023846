import React from "react";
import { connect } from "react-redux";
import PageTitle from "../components/PageTitle";
import { update_page_meta } from "../actions/page/header";

const PrivacyPolicy = ({ update_page_meta }) => {
    update_page_meta({
        title: "Privacy Policy | Aquarius HR Consultants",
        description: ""
    })
    return (
        <>
            <PageTitle title="Privacy Policy" description="" breadcrumbs={[{title: "Home", url: "/"}, {title: "Privacy Policy"}]} />

            <section style={{padding: "40px 0px"}}>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-12 col-md-12 col-sm-12 content-side">
                            <div class="job-details-content">
                                <div class="text">
                                    <p>
                                        At Aquarius HR Consultants Private Limited, we are committed to protecting the privacy and confidentiality of our website visitors and clients. This Privacy Policy outlines how we collect, use, disclose, and protect personal information gathered through our website. By accessing and using our website, you consent to the terms of this Privacy Policy.
                                    </p>
                                    <h3>Information Collection:</h3>
                                    <p>
                                        <b>Personal Information</b>: We may collect personal information, such as name, email address, phone number, and company details, when you voluntarily provide it through our website's contact forms or other communication channels.
                                    </p>
                                    <p>
                                        <b>Usage Information</b>: We may gather non-personal information, such as IP address, browser type, operating system, and website usage statistics, through cookies and similar technologies.
                                    </p>
                                    <h3>Use of Information:</h3>
                                    <p>
                                        <b>Personal Information</b>: We use personal information to respond to your inquiries, provide requested services, and communicate with you about our offerings, promotions, and updates.
                                    </p>
                                    <p>
                                        <b>Usage Information</b>: We analyze usage information to improve our website, enhance user experience, and understand trends and preferences of our visitors.
                                    </p>
                                    <h3>Information Sharing:</h3>
                                    <p>
                                    We do not sell, trade, or disclose your personal information to third parties without your consent, except as required by law or as necessary to fulfill our business obligations.
                                    </p>
                                    <p>
                                    We may share personal information with trusted service providers who assist us in delivering our services, subject to confidentiality agreements and data protection standards.
                                    </p>
                                    <h3>Data Security:</h3>
                                    <p>We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.</p>
                                    <p>However, no data transmission or storage system can be guaranteed to be 100% secure. You acknowledge that any information you provide is at your own risk.</p>
                                    <h3>Third-Party Websites:</h3>
                                    <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of those websites. We encourage you to review their respective privacy policies.</p>
                                    <h3>Consent and Opt-Out:</h3>
                                    <p>By providing your personal information, you consent to the collection, use, and disclosure as outlined in this Privacy Policy.</p>
                                    <p>You may opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in those communications.</p>
                                    <h3>Updates to the Privacy Policy:</h3>
                                    <p>We reserve the right to update this Privacy Policy from time to time. Any changes will be effective immediately upon posting on our website. We encourage you to review this page periodically for any updates.</p>
                                    <br/><br/>
                                    <p>If you have any questions or concerns about our Privacy Policy or the handling of your personal information, please contact us using the information provided on our website.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default connect(null, { update_page_meta })(PrivacyPolicy);