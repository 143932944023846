export const team = {
    leadership: [
        {
            id: 1,
            name: "Purnima Kajla",
            designation: "Director & Founder",
            profile: <p className="management-team-profile-p">
                {new Date().getFullYear() - 2010}+ years of entrepreneurial experience responsible for leading the company’s vision, driving its business growth
                <br /><br />
                Before that, she spent ten years at Henkel as a founding member of a new line of acoustic products for industrial, automotive, and consumer durable applications. Worked on job rotations in Germany, France, Korea & Australia as a part of a Technology transfer assignment. Post-graduation from IIT Delhi.
            </p>,
            imgUrl: "purnima.webp"
        },
        {
            id: 2,
            name: "Damayanti Rajpal",
            designation: "Partner & Advisor",
            profile: <p className="management-team-profile-p">
                Damayanti Rajpal is a Finance professional with {new Date().getFullYear() - 2003} years of experience in the Internal Audit stream. Currently also consulting in Internal Audit, Credit and collection, budgeting and Information Security. She has vast experience in the domain of Enterprise Risk Management, Internal Audit, Sox Compliance, IFC, Developing manuals and Process redesigning, and Management Assurance Reviews. She has worked for reputed Brands/MNCs and has been responsible for training, performance appraisal and team development. Graduated from Shri Ram College of Commerce, DU & CA in 2003.
            </p>,
            imgUrl: "Damayanti.webp"
        },
        {
            id: 3,
            name: "Ankita Paliwal",
            designation: "Head Operations",
            profile: <p className="management-team-profile-p">
                Heading the service delivery that brings the full potential of Aquarius HR consultants to our clients. Her role as Head of operations is to ensure client success. Ankita brings in close to {new Date().getFullYear() - 2011}  years of experience and has a successful track record to help the organization grow existing businesses and accelerate new businesses. Responsible for managing the delivery of all services that improve customer value and ROI. Self-starter with strong interpersonal skills.
                <br /><br />
                Ankita Paliwal holds a masters degree in Human resources from GNIT Gr. Noida.
            </p>,
            imgUrl: "ankita.webp"
        }
    ]
}