import React from "react";
import { connect } from "react-redux";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import clients from '../data/clients.json';
import { Link } from "react-router-dom";

const ClientSlider = ({ className='' }) => {
    return (
        <section className={`clients-section ${className.length > 0 ? className : ''}`}>
            <div className="outer-container">
                <Swiper
                    className="clients-carousel"
                    modules={[Autoplay]}
                    loop={true}
                    speed={3000}
                    freeMode={true}
                    autoplay={{
                        delay: 0,
                        disableOnInteraction: false
                    }}
                    spaceBetween={0}
                    slidesPerView={1}
                    breakpoints={{
                        0: {
                            slidesPerView: 1
                        },
                        480: {
                            slidesPerView: 2
                        },
                        600: {
                            slidesPerView: 3
                        },
                        800: {
                            slidesPerView: 4
                        },
                        1200: {
                            slidesPerView: 6
                        }
                    }}
                    >
                        {
                            clients.map(client => {
                                return (
                                    <SwiperSlide key={client.id}>
                                        <figure className="clients-logo-box">
                                            <img src={require(`../assets/images/clients/${client?.uri}`)} style={{height: '80px', width: "135px"}} alt={client?.name} />
                                            <span className="logo-title"><Link to="#">{client?.name}</Link></span>
                                        </figure>
                                    </SwiperSlide>
                                )
                            })
                        }
                        
                        {/* <SwiperSlide>
                            <figure className="clients-logo-box">
                                <a href="index.html"><img src="assets/images/clients/clients-logo-1.png" alt="" /></a>
                                <span className="logo-title"><a href="index.html">Visit Website</a></span>
                            </figure>
                        </SwiperSlide>
                        <SwiperSlide>
                            <figure className="clients-logo-box">
                                <a href="index.html"><img src="assets/images/clients/clients-logo-1.png" alt="" /></a>
                                <span className="logo-title"><a href="index.html">Visit Website</a></span>
                            </figure>
                        </SwiperSlide>
                        <SwiperSlide>
                            <figure className="clients-logo-box">
                                <a href="index.html"><img src="assets/images/clients/clients-logo-1.png" alt="" /></a>
                                <span className="logo-title"><a href="index.html">Visit Website</a></span>
                            </figure>
                        </SwiperSlide>
                        <SwiperSlide>
                            <figure className="clients-logo-box">
                                <a href="index.html"><img src="assets/images/clients/clients-logo-1.png" alt="" /></a>
                                <span className="logo-title"><a href="index.html">Visit Website</a></span>
                            </figure>
                        </SwiperSlide>
                        <SwiperSlide>
                            <figure className="clients-logo-box">
                                <a href="index.html"><img src="assets/images/clients/clients-logo-1.png" alt="" /></a>
                                <span className="logo-title"><a href="index.html">Visit Website</a></span>
                            </figure>
                        </SwiperSlide>
                        <SwiperSlide>
                            <figure className="clients-logo-box">
                                <a href="index.html"><img src="assets/images/clients/clients-logo-1.png" alt="" /></a>
                                <span className="logo-title"><a href="index.html">Visit Website</a></span>
                            </figure>
                        </SwiperSlide>
                        <SwiperSlide>
                            <figure className="clients-logo-box">
                                <a href="index.html"><img src="assets/images/clients/clients-logo-1.png" alt="" /></a>
                                <span className="logo-title"><a href="index.html">Visit Website</a></span>
                            </figure>
                        </SwiperSlide>
                        <SwiperSlide>
                            <figure className="clients-logo-box">
                                <a href="index.html"><img src="assets/images/clients/clients-logo-1.png" alt="" /></a>
                                <span className="logo-title"><a href="index.html">Visit Website</a></span>
                            </figure>
                        </SwiperSlide>
                        <SwiperSlide>
                            <figure className="clients-logo-box">
                                <a href="index.html"><img src="assets/images/clients/clients-logo-1.png" alt="" /></a>
                                <span className="logo-title"><a href="index.html">Visit Website</a></span>
                            </figure>
                        </SwiperSlide>
                        <SwiperSlide>
                            <figure className="clients-logo-box">
                                <a href="index.html"><img src="assets/images/clients/clients-logo-1.png" alt="" /></a>
                                <span className="logo-title"><a href="index.html">Visit Website</a></span>
                            </figure>
                        </SwiperSlide>
                        <SwiperSlide>
                            <figure className="clients-logo-box">
                                <a href="index.html"><img src="assets/images/clients/clients-logo-1.png" alt="" /></a>
                                <span className="logo-title"><a href="index.html">Visit Website</a></span>
                            </figure>
                        </SwiperSlide> */}
                </Swiper>
            </div>
        </section>
    )
}

export default connect(null, {})(ClientSlider);