import React from "react";
import { Link } from "react-router-dom";

const AboutSection = ({}) => {
    return (
        <section className="about-section">
            <div className="pattern-layer" style={{backgroundImage: `url(${require('../../assets/images/shape/pattern-5.png')})`}}></div>
            <div className="auto-container">
                <div className="inner-container">
                    <div className="row clearfix">
                        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div id="content_block_2">
                                <div className="content-box centred">
                                    <figure className="image-box"><img src={require("../../assets/images/resource/about-1.webp")} alt="" /></figure>
                                    <div className="inner-box">
                                        <figure className="icon-box"><img src={require("../../assets/images/icons/icon-5.png")} alt="" /></figure>
                                        <h3>Our accomplished leaders bring a wealth of expertise and experience to our organization</h3>
                                        <Link to="/about/management-team/">Our Visionary Leaders<i className="flaticon-right-arrow"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div id="content_block_3">
                                <div className="content-box">
                                    <div className="sec-title">
                                        <span className="top-title">About AHCPL</span>
                                        <h2>Discovering Executives. Building Success.</h2>
                                    </div>
                                    <div className="text">
                                        <p>
                                            At AHCPL, we are dedicated to providing exceptional services in the field of executive search and talent acquisition. 
                                            With a strong reputation built over {new Date().getFullYear() - 2010} years, we have become a trusted partner for leading 
                                            Indian and multinational companies in India.
                                        </p>
                                    </div>
                                    <div className="link"><Link to="/about/"><i className="flaticon-right-arrow"></i>More About Us</Link></div>
                                    <div className="author-box">
                                        <div className="author-text">
                                            <h3>We strive to be the preferred executive search partner for our clients, delivering outstanding results and contributing to their success</h3>
                                        </div>
                                        {/* <div className="author-info">
                                            <figure className="author-thumb"><img src={require("../../assets/images/resource/author-1.png")} alt="" /></figure>
                                            <h4>Purnima Kajla</h4>
                                            <span className="designation">Founder</span>
                                            <figure className="signature"><img src={require("../../assets/images/icons/signature-1.png")} alt="" /></figure>
                                        </div> */}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutSection;