import React from "react";
import { connect } from "react-redux";
import TestimonialSlider from "../../components/testimonials/TestimonialSlider";
import ClientSliderWithCTA from "../../components/ClientSliderWithCTA";
import roles from '../../data/functionalroles.json';
import PageTitle from "../../components/PageTitle";
import { update_page_meta } from "../../actions/page/header";


const FunctionalRoles = ({ update_page_meta }) => {
    update_page_meta({
        title: "Functional Roles | Aquarius HR Consultants",
        description: "Aquarius HR Consultants specializes in identifying and filling functional roles with top talent. From executive-level positions to specialized roles, we have the knowledge and expertise to match your organization's needs with the perfect candidates. Whether you're looking for a visionary CEO, a strategic CFO, or a dynamic executive leader, our comprehensive recruitment process ensures that we find the ideal fit for your functional requirements. Trust Aquarius HR Consultants to help you build a high-performing team that drives your organization's success."
    })
    return (
        <>
            <PageTitle title="Functional Roles" description="" breadcrumbs={[{title: "Home", url: "/"}, {title: "Services", url: "/services/"}, {title: "Functional Roles"}]} />
            <section className="chooseus-section sec-pad-2">
                <div className="auto-container">
                    <div className="row clearfix g-5">
                        {
                            roles.map((item, index) => {
                                var ul = document.createElement('template');
                                item.list_html = item.list_html.trim();
                                ul.innerHTML = item.list_html;
                                console.log(ul)
                                return (
                                    <div className="col-lg-3 col-md-6 col-sm-12 chooseus-block" key={item.id}>
                                        <div className="chooseus-block-one wow fadeInUp animated animated" style={{height: "100%"}} data-wow-delay={`${index * 200}ms`} data-wow-duration="1500ms">
                                            <div className="inner-box" style={{height: "100%"}} dangerouslySetInnerHTML={{ __html: item.list_html }} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            
            <TestimonialSlider />
            <ClientSliderWithCTA />
        </>
    )
}

export default connect(null, { update_page_meta })(FunctionalRoles);