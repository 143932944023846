import React from "react";
import { connect } from "react-redux";
import PageTitle from "../components/PageTitle";
import { update_page_meta } from "../actions/page/header";

const Disclaimer = ({ update_page_meta }) => {
    update_page_meta({
        title: "Disclaimer | Aquarius HR Consultants",
        description: ""
    })
    return (
        <>
            <PageTitle title="Disclaimer" description="" breadcrumbs={[{title: "Home", url: "/"}, {title: "Disclaimer"}]} />

            <section style={{padding: "40px 0px"}}>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-12 col-md-12 col-sm-12 content-side">
                            <div class="job-details-content">
                                <div class="text">
                                    <p>The information provided on the Aquarius HR Consultants Private Limited website(www.aquariusconsultants.in) is for general informational purposes only. While we strive to ensure the accuracy and reliability of the information, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website. Any reliance you place on such information is therefore strictly at your own risk.</p>
                                    <p>The website may contain links to external websites that are not controlled or maintained by Aquarius HR Consultants. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorsement of the views expressed within them.</p>
                                    <p>Under no circumstances will Aquarius HR Consultants be liable for any loss or damage including, without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.</p>
                                    <p>Every effort is made to keep the website up and running smoothly. However, Aquarius HR Consultants takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.</p>
                                    <br /><br/>
                                    <p>If you have any questions or concerns about this disclaimer, please contact us using the information provided on our website.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default connect(null, { update_page_meta })(Disclaimer);