import React, { useEffect, useState } from "react";
import useScrollPosition from "../hooks/useScrollPosition";

const ScrollToTop = () => {
    const scrollPosition = useScrollPosition();
    const [open, setOpen] = useState('');
    useEffect(() => {
        if (scrollPosition >= 110){
            setOpen('open');
        }
        else{
            setOpen('');
        }
    }, [scrollPosition]);
    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
    return (
        <button className={`scroll-top scroll-to-target ${open}`} onClick={scrollToTop}><i className="flaticon-up-arrow-1"></i>Top</button>
    )
}

export default ScrollToTop;