import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

const Banner = () => {
    return (
        <section className="banner-section style-one">
            <Swiper
                className="banner-carousel"
                modules={[Autoplay]}
                autoplay={{
                    delay: 8000,
                    disableOnInteraction: false
                }}
                loop={true}
                slidesPerView={1}
                // onSwiper={(swiper) => console.log(swiper)}
                // onSlideChange={() => console.log('slide change')}
                >
                    <SwiperSlide>
                        <div className="slide-item style-one">
                            {/* <div className="image-layer" style={{backgroundImage:`url(${require('../../assets/images/banner/business-deal.jpg')})`}}></div> */}
                            <div className="image-layer" style={{backgroundImage:`url(${require('../../assets/images/banner/business-deal.svg').default})`}}></div>
                            <div className="pattern-layer">
                                <div className="pattern-1" style={{backgroundImage: `url(${require('../../assets/images/shape/pattern-1.png')})`}}></div>
                                <div className="pattern-2" style={{backgroundImage: `url(${require('../../assets/images/shape/pattern-2.png')})`}}></div>
                            </div>
                            <div className="auto-container">
                                <div className="row clearfix">
                                    <div className="col-xl-6 col-lg-12 col-md-12 content-column">
                                        <div className="content-box">
                                            <span>Executive Search</span>
                                            <h1>Finding executives who shape the future.</h1>
                                            <p>Identify and hire top-level executives.</p>
                                            <div className="btn-box">
                                                <Link to="/services/executive-search-services/" className="theme-btn-two">Learn More</Link>
                                            </div>
                                        </div> 
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slide-item style-two">
                            <div className="image-layer" style={{backgroundImage:`url(${require('../../assets/images/banner/explaining-analytical-chart.webp')})`}}></div>
                            <div className="pattern-layer">
                                <div className="pattern-1" style={{backgroundImage: `url(${require('../../assets/images/shape/pattern-3.png')})`}}></div>
                                <div className="pattern-2" style={{backgroundImage: `url(${require('../../assets/images/shape/pattern-4.png')})`}}></div>
                            </div>
                            <div className="auto-container">
                                <div className="row clearfix">
                                    <div className="col-xl-6 col-lg-12 col-md-12 offset-xl-6 content-column">
                                        <div className="content-box">
                                            <span>Organizational Mapping</span>
                                            <h1>Charting the path to optimal organizational performance.</h1>
                                            <p>Optimize organizational structure, roles, and processes.</p>
                                            <div className="btn-box">
                                                <Link to="/services/organizational-mapping/" className="theme-btn-two">Learn More</Link>
                                            </div>
                                        </div> 
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slide-item style-one">
                            <div className="image-layer" style={{backgroundImage:`url(${require('../../assets/images/banner/office-employee-making-checklist.webp')})`}}></div>
                            <div className="pattern-layer">
                                <div className="pattern-1" style={{backgroundImage: `url(${require('../../assets/images/shape/pattern-1.png')})`}}></div>
                                <div className="pattern-2" style={{backgroundImage: `url(${require('../../assets/images/shape/pattern-2.png')})`}}></div>
                            </div>
                            <div className="auto-container">
                                <div className="row clearfix">
                                    <div className="col-xl-7 col-lg-12 col-md-12 content-column">
                                        <div className="content-box">
                                            <span>Executive Assessment</span>
                                            <h1>Insightful assessment for exceptional leadership</h1>
                                            <p>Make informed decisions about talent development, succession planning, and leadership effectiveness.</p>
                                            <div className="btn-box">
                                                <Link to="/services/executive-assessment/" className="theme-btn-two">Learn More</Link>
                                            </div>
                                        </div>  
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </SwiperSlide>
            </Swiper>
        </section>
    )
}

export default connect(null, {})(Banner);