import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { addressHtml, email, mailto, phone, telLink, officeaddressHtml } from "../constants/contact";
import { linkedin } from "../constants/socialmedia";

const Footer = ({}) => {
    return (
        <footer className="main-footer bg-color-1">
            <div className="pattern-layer" style={{backgroundImage: `url(${require('../assets/images/shape/pattern-11.png')})`}}></div>
            <div className="auto-container">
                <div className="footer-top">
                    <div className="widget-section">
                        <div className="row clearfix">
                            <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                                <div className="footer-widget about-widget">
                                    <div className="widget-title">
                                        <h3>Aquarius HR Consultants Pvt. Ltd.</h3>
                                    </div>
                                    <div className="text">
                                        <p>We are the preferred executive search firm for leading organizations in India and multinational corporations operating in the Indian market</p>
                                        <ul>
                                            <li>
                                                CIN: U74140DL2010PTC200720
                                            </li>
                                            <li>
                                                UDYAM Reg. No: UDYAM-DL-10-0010229
                                            </li>
                                        </ul>
                                    </div>
                                    <ul className="social-links clearfix">
                                        <li><h5>Are we connected?</h5></li>
                                        <li><a href={linkedin} target="_blank"><i className="fab fa-linkedin"></i></a></li>
                                        {/* <li><a href={facebook} target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href={twitter} target="_blank"><i className="fab fa-twitter"></i></a></li>
                                        <li><a href={youtube} target="_blank"><i className="fab fa-youtube"></i></a></li>
                                        <li><a href={instagram} target="_blank"><i className="fab fa-instagram"></i></a></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                                <div className="footer-widget links-widget">
                                    <div className="widget-title">
                                        <h3>Useful Links</h3>
                                    </div>
                                    <ul className="links-list clearfix">
                                        <li><Link to="/about/">About</Link></li>
                                        <li><Link to="/services/">Services</Link></li>
                                        <li><Link to="/services/industries/">Industries</Link></li>
                                        <li><Link to="/services/functional-roles/">Functional Roles</Link></li>
                                        <li><Link to="/contact/">Contact</Link></li>
                                        <li><Link to="/compliance/">Compliance</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                                <div className="footer-widget contact-widget footer-office">
                                    <div className="single-info-box">
                                        <div className="widget-title">
                                            <h3>Locations</h3>
                                        </div>
                                        <h4 style={{color: "white", fontSize: "18px"}}>Gurugram</h4>
                                        {officeaddressHtml} <br />
                                        <h4 style={{color: "white", fontSize: "18px"}}>Registered Office</h4>
                                        {addressHtml}
                                        
                                        {/* <ul className="info-box clearfix">
                                            <li><a href={telLink}>{phone}</a></li>
                                            <li><a href={mailto}>{email}</a></li>
                                        </ul> */}
                                    </div>
                                    {/* <div className="single-info-box">
                                        <div className="widget-title">
                                            <h3>For Employees</h3>
                                        </div>
                                        <ul className="info-box clearfix">
                                            <li><a href={telLink}>{phone}</a></li>
                                            <li><a href={mailto}>{email}</a></li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-contact">
                    <ul>
                        <li>
                            <a href={telLink}><i className="flaticon-phone-call"></i> {phone}</a>
                        </li>
                        <li>
                            <a href={mailto}><i className="flaticon-email"></i> {email}</a>
                        </li>
                    </ul>
                </div>
                {/* <div className="footer-subscribe">
                    <div className="text centred"><h3>Subscribe Us & Get Our Recruitment Updates in Your Inbox</h3></div>
                    <form action="contact.html" method="post" className="subscribe-form">
                        <div className="form-group">
                            <input type="email" name="email" placeholder="Enter your email address here ..." required="" />
                            <button type="submit"><i className="flaticon-right-arrow"></i>Subscribe US</button>
                        </div>
                    </form>
                </div> */}
                <div className="footer-bottom clearfix">
                    <div className="copyright pull-left"><p>&copy; {new Date().getFullYear() > 2023 && `2023-`}{new Date().getFullYear()} <Link to="/">Aquarius HR Consultants Pvt. Ltd.</Link> Developed by <a href="https://www.ampliquity.com/" target="_blank">Ampliquity Technologies</a>. All Rights Reserved.</p></div>
                    <ul className="footer-nav pull-right clearfix">
                        <li><Link to="/privacy-policy/">Privacy Policy</Link></li>
                        {/* <li><Link to="/terms-and-conditions/">Terms & Conditions</Link></li> */}
                        <li><Link to="/disclaimer/">Disclaimer</Link></li>
                        {/* <li><Link to="/sitemap/">Site Map</Link></li> */}
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default connect(null, {})(Footer);