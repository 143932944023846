import React from "react";
import { connect } from "react-redux";
import PageTitle from "../../components/PageTitle";
import services from '../../data/services.json';
import { Link } from "react-router-dom";
import TestimonialSlider from "../../components/testimonials/TestimonialSlider";
import ClientSliderWithCTA from "../../components/ClientSliderWithCTA";
import { update_page_meta } from "../../actions/page/header";

const Services = ({ update_page_meta }) => {
    update_page_meta({
        title: "Services | Aquarius HR Consultants",
        description: "Aquarius HR Consultants is a leading executive search firm specializing in identifying and recruiting top-level executives for your organization. Our executive search services are designed to help you find visionary leaders, strategic thinkers, and exceptional professionals who can drive your organization's success. With our extensive network, rigorous assessment methods, and deep industry knowledge, we ensure that you have access to the best executive talent available. Partner with Aquarius HR Consultants to elevate your executive team and secure the right leaders who will make a lasting impact on your organization's growth and transformation."
    })
    return (
        <>
            <PageTitle title="Services" description="" breadcrumbs={[{title: "Home", url: "/"}, {title: "Services"}]} />

            <section className="service-section service-page">
                <div className="anim-icon">
                    <div className="icon-1" style={{backgroundImage: `url('../../assets/images/icons/anim-icon-1.png')`}}></div>
                    <div className="icon-2"></div>
                </div>
                <div className="auto-container">
                    <div className="sec-title centred">
                        <span className="top-title">Range of Services</span>
                        <h2>Our Comprehensive Offerings</h2>
                        <p>Designed to support our clients in attracting and selecting top executive talent, gaining market insights, and making informed hiring decisions to drive organizational success.</p>
                    </div>
                    <div className="row clearfix">
                        {
                            services.map(service => {
                                return (
                                    <div className="col-lg-4 col-md-6 col-sm-12 service-block" key={service.id}>
                                        <div className="service-block-one">
                                            <div className="inner-box">
                                                <figure className="image-box"><img src={require(`../../assets/images/service/${service.imgSmall}`)} alt={service.title} /></figure>
                                                <div className="lower-content">
                                                    <div className="content-box">
                                                        <div className="inner">
                                                            <figure className="icon-box"><img src={require(`../../assets/images/icons/${service.icon}`)} alt={service.title} /></figure>
                                                            <h4>{service.title}</h4>
                                                        </div>
                                                        <div className="link"><Link to={service.uri}>More Details</Link></div>
                                                    </div>
                                                    <div className="overlay-content">
                                                        <p>{service.smallDescription}</p>
                                                        <Link to={service.uri}><i className="flaticon-right-arrow"></i>More details</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>

            <section className="hiring-section">
                <div className="image-layer">
                    <figure className="image-1"><img src={require("../../assets/images/resource/industries.webp")} alt="" /></figure>
                    <figure className="image-2"><img src={require("../../assets/images/resource/functionalroles.webp")} alt="" /></figure>
                </div>
                <div className="outer-container clearfix" style={{display: "flex", flexWrap: "wrap"}}>
                    <div className="left-column pull-left clearfix">
                        <div className="inner-box pull-right">
                            <div className="overlay-black-lg">
                                <div className="icon-box"><i className="flaticon-factory"></i></div>
                                <h2>Industries We Serve</h2>
                                <p>At Aquarius, we have extensive expertise and experience serving a diverse range of industries. </p>
                                <Link to="/services/industries/">Industries</Link>
                            </div>
                            
                        </div>
                    </div>
                    <div className="right-column pull-right clearfix">
                        <div className="inner-box pull-left">
                            <div className="overlay-black-lg">
                                <div className="icon-box"><i className="flaticon-working-man"></i></div>
                                <h2>Functional Roles</h2>
                                <p>We specialize in executive search services for a wide range of positions</p>
                                <Link to="/services/functional-roles/">Functional Roles</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TestimonialSlider />
            <ClientSliderWithCTA />

        </>
    )
}

export default connect(null, { update_page_meta })(Services);