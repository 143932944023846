import React from "react";
import { connect } from "react-redux";
import PageTitle from "../components/PageTitle";
import TestimonialSlider from "../components/testimonials/TestimonialSlider";
import ClientSliderWithCTA from "../components/ClientSliderWithCTA";
import { update_page_meta } from "../actions/page/header";

const About = ({ update_page_meta }) => {
    update_page_meta({
        title: "About Aquarius HR Consultants | Executive Search Firm",
        description: "Learn more about Aquarius HR Consultants, a premier executive search firm dedicated to finding exceptional leaders for your organization. With a track record of success and a client-centric approach, we specialize in identifying top-level executive talent across industries. Our team of experienced consultants combines industry expertise, a vast professional network, and a rigorous assessment process to ensure that we deliver the best candidates who align with your organization's vision and values. Discover how Aquarius HR Consultants can partner with you in building a strong leadership team that drives your business forward."
    })
    return (
        <>
            <PageTitle title="About" description="" breadcrumbs={[{title: 'Home', url: "/"}, {title: "About"}]} />
            <section className="about-style-two about-page">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="row clearfix">
                            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                                <div id="image_block_1">
                                    <div className="image-box" style={{padding: 0}}>
                                        <div className="pattern-layer" style={{backgroundImage: `url(${require('../assets/images/shape/pattern-14-green.png')})`}}></div>
                                        {/* <div className="video-inner" style={{backgroundImage: `url({require('../assets/images/resource/video-bg-1.jpg')})`}}>
                                            <a href="https://www.youtube.com/watch?v=nfP5N9Yc72A&amp;t=28s" className="lightbox-image video-btn" data-caption="">
                                                <i className="flaticon-play"></i>
                                            </a>
                                            <div className="border"></div>
                                        </div> */}
                                        <figure className="image-1"><img src={require("../assets/images/resource/about-2.webp")} alt="" /></figure>
                                        {/* <figure className="image-2 wow slideInLeft animated animated" data-wow-delay="00ms"><img src={require("../assets/images/resource/briefcase-1.png")} alt="" /></figure> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                                <div id="content_block_3">
                                    <div className="content-box">
                                        <div className="sec-title">
                                            <span className="top-title">ABOUT AQUARIUS HR CONSULTANTS PVT. LTD.</span>
                                            <h2>Discovering Executives. Building Success.</h2>
                                        </div>
                                        <div className="text">
                                            <p>
                                                At AHCPL, we are dedicated to providing exceptional services in the field of executive search and talent acquisition. 
                                                With a strong reputation built over {new Date().getFullYear() - 2010} years, we have become a trusted partner for leading 
                                                Indian and multinational companies in India.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="process-style-two alternet-2">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="pattern-layer" style={{backgroundImage: `url(${require('../assets/images/shape/pattern-36.webp')})`}}></div>
                        <div className="sec-title light centred">
                            <span className="top-title">Core Values</span>
                            <h2>Our Working Principles</h2>
                        </div>
                        <div className="row clearfix">
                            <div className="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div className="single-item">
                                    <div className="inner-box">
                                        {/* <span>Step</span> */}
                                        <h2>01...</h2>
                                        <figure className="icon-box"><img src={require("../assets/images/icons/icon-51.png")} alt="" /></figure>
                                        <h3>Partnership</h3>
                                        <p>AHCPL is committed to providing dedicated and decisive support to our clients. We work closely with our clients' management and aims to understand their 
                                            priorities thoroughly to deliver high-quality services that meet their needs.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div className="single-item">
                                    <div className="inner-box">
                                        {/* <span>Step</span> */}
                                        <h2>02...</h2>
                                        <figure className="icon-box"><img src={require("../assets/images/icons/icon-52.png")} alt="" /></figure>
                                        <h3>Integrity</h3>
                                        <p>We operate in an environment of openness and transparency. We believe in representing our clients to candidates and our candidates to clients in an honest manner. 
                                            We are committed to delivering on our promises and ensuring that clients and candidates receive the services as promised.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div className="single-item">
                                    <div className="inner-box">
                                        {/* <span>Step</span> */}
                                        <h2>03...</h2>
                                        <figure className="icon-box"><img src={require("../assets/images/icons/icon-53.png")} alt="" /></figure>
                                        <h3>Confidentiality</h3>
                                        <p>We place a high value on confidentiality and the protection of information that has been entrusted to us. We recognize that our clients and other stakeholders may 
                                            share sensitive or confidential information during the course of the work, and we are committed to treating this information with the utmost respect.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="growth-style-two bg-color-2">
                <div className="auto-container">
                    <div className="row align-items-center clearfix">
                        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div id="content_block_1">
                                <div className="content-box">
                                    <div className="sec-title">
                                        <span className="top-title">Time to grow</span>
                                        <h2>Our Vision</h2>
                                    </div>
                                    <div className="inner-box">
                                        <div className="text">
                                            <p>
                                                To be the leading executive search firm in India, renowned for our exceptional client service, innovative search solutions, 
                                                and unwavering commitment to ethics and integrity.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div id="content_block_1">
                                <div className="content-box">
                                    <div className="sec-title">
                                        <span className="top-title">Time to grow</span>
                                        <h2>Our Mission</h2>
                                    </div>
                                    <div className="inner-box">
                                        <div className="text">
                                            <p>
                                                At AHCPL, we are dedicated to delivering superior search services that enable our clients to achieve their strategic objectives. 
                                                We do this by leveraging our extensive expertise, state-of-the-art technology, and global network to identify and attract top talent across industries 
                                                and geographies. Our mission is to exceed our clients' expectations and provide exceptional value by delivering innovative and customized search 
                                                solutions that meet their unique needs. We are committed to operating with the highest standards of ethics and integrity, building long-term 
                                                partnerships with our clients, and contributing to the success of their businesses.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section className="history-section">
                <figure className="image-layer"><img src={require("../assets/images/resource/history-1.png")} alt="" /></figure>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-4 col-md-12 col-sm-12 column">
                            <div className="sec-title">
                                <span className="top-title">Our History</span>
                                <h2>Taking a Look Back of Our History</h2>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 column">
                            <div className="inner-box">
                                <figure className="image-box">
                                    <img src={require("../assets/images/resource/history-1.jpg")} alt="" />
                                    <div className="dots-box"></div>
                                </figure>
                                <div className="content-box">
                                    <div className="dots-box active"></div>
                                    <div className="year-box">
                                        <h3>2010</h3>
                                        <div className="pattern-1" style={{backgroundImage: `url(${require('../assets/images/shape/pattern-37.png')})`}}></div>
                                        <div className="pattern-2" style={{backgroundImage: `url(${require('../assets/images/shape/pattern-38.png')})`}}></div>
                                    </div>
                                    <div className="text">
                                        <h3>Fast Growing Company</h3>
                                        <p>To take a trivial example, which of us ever undertake laborius physical exercise except to obtain some advantage from it.</p>
                                    </div>
                                </div>
                                <figure className="image-box ">
                                    <img src={require("../assets/images/resource/history-2.jpg")} alt="" />
                                    <div className="dots-box"></div>
                                </figure>
                                <div className="content-box mr-0">
                                    <div className="dots-box"></div>
                                    <div className="year-box">
                                        <h3>2014</h3>
                                        <div className="pattern-1" style={{backgroundImage: `url(${require('../assets/images/shape/pattern-37.png')})`}}></div>
                                        <div className="pattern-2" style={{backgroundImage: `url(${require('../assets/images/shape/pattern-38.png')})`}}></div>
                                    </div>
                                    <div className="text">
                                        <h3>1000 Companies Tie-up</h3>
                                        <p>Must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and account.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 column">
                            <div className="inner-box">
                                <div className="content-box">
                                    <div className="year-box">
                                        <h3>2008</h3>
                                        <div className="pattern-1" style={{backgroundImage: `url(${require('../assets/images/shape/pattern-37.png')})`}}></div>
                                        <div className="pattern-2" style={{backgroundImage: `url(${require('../assets/images/shape/pattern-38.png')})`}}></div>
                                    </div>
                                    <div className="text">
                                        <h3>Started in Gurgaon</h3>
                                        <p>Must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and account.</p>
                                    </div>
                                </div>
                                <figure className="image-box"><img src={require("../assets/images/resource/history-3.jpg")} alt="" /></figure>
                                <div className="content-box">
                                    <div className="year-box">
                                        <h3>2011</h3>
                                        <div className="pattern-1" style={{backgroundImage: `url(${require('../assets/images/shape/pattern-37.png')})`}}></div>
                                        <div className="pattern-2" style={{backgroundImage: `url(${require('../assets/images/shape/pattern-38.png')})`}}></div>
                                    </div>
                                    <div className="text">
                                        <h3>Best Staffing Talent Award </h3>
                                        <p>To take a trivial example, which of us ever undertake laborius physical exercise except to obtain some advantage from it.</p>
                                    </div>
                                </div>
                                <figure className="image-box mr-0"><img src={require("../assets/images/resource/history-4.jpg")} alt="" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            

            {/* <section className="awards-section">
                <div className="pattern-layer" style={{backgroundImage: `url(${require('../assets/images/shape/pattern-10.png')})`}}></div>
                <div className="auto-container">
                    <div className="sec-title centred">
                        <span className="top-title">Our Excellence</span>
                        <h2>Awards & Major Achievements</h2>
                        <p>Long established fact that a reader will be distracted by the <br />readable content of a page.</p>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-4 col-md-6 col-sm-12 inner-column">
                            <div className="inner-block">
                                <div className="single-award-block">
                                    <div className="inner-box">
                                        <div className="upper-box">
                                            <figure className="icon-box"><img src={require("../assets/images/icons/icon-19.png")} alt="" /></figure>
                                            <h3>Best of Staffing Talent Award</h3>
                                        </div>
                                        <ul className="lower-box">
                                            <li><span>Year</span>:2009-2010</li>
                                            <li><span>Award by</span>:Los Vegas Business Time</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="single-award-block">
                                    <div className="inner-box">
                                        <div className="upper-box">
                                            <figure className="icon-box"><img src={require("../assets/images/icons/icon-19.png")} alt="" /></figure>
                                            <h3>Best Companies to Work in Gurgaon</h3>
                                        </div>
                                        <ul className="lower-box">
                                            <li><span>Year</span>:2012-2013</li>
                                            <li><span>Award by</span>:Sparks Group</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 image-column">
                            <figure className="image-box js-tilt"><img src={require("../assets/images/resource/award-1.png")} alt="" /></figure>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 inner-column">
                            <div className="inner-block">
                                <div className="single-award-block">
                                    <div className="inner-box">
                                        <div className="upper-box">
                                            <figure className="icon-box"><img src={require("../assets/images/icons/icon-19.png")} alt="" /></figure>
                                            <h3>Fast Growing Staffing Firms</h3>
                                        </div>
                                        <ul className="lower-box">
                                            <li><span>Year</span>:2015</li>
                                            <li><span>Award by</span>:Dallas Association 100</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="single-award-block">
                                    <div className="inner-box">
                                        <div className="upper-box">
                                            <figure className="icon-box"><img src={require("../assets/images/icons/icon-19.png")} alt="" /></figure>
                                            <h3>Best of Staffing Client Satisfaction</h3>
                                        </div>
                                        <ul className="lower-box">
                                            <li><span>Year</span>:2018-2019</li>
                                            <li><span>Award by</span>:Forbes International</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <TestimonialSlider />

            <ClientSliderWithCTA />
        </>
    )
}

export default connect(null, { update_page_meta })(About);