import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm = () => {
    const [Form, setForm] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    })
    const siteKey = '6LeGbr0mAAAAAFsm0B7tnDgPbs7R3-mTwGZPFf4o';
    const captchaRef = useRef(null);
    const [isRecaptchaChecked, setIsRecaptchaChecked] = useState(false);
    const handleChange = (e) => {
        setForm({...Form, [e.target.name] : e.target.value})
    }
    const [Submitting, setSubmitting] = useState(false);

    const saveForm = async ( recaptchaToken ) => {
        
        setSubmitting(true);
        const newRecord = {
            title: Form.name,
            content: Form.message,
            fields: {
                email: Form.email,
                phone: Form.phone
            },
            status: 'publish',
            recaptchaToken: recaptchaToken
        };

        try {
            const response = await axios.post(
              'https://portal.aquariusconsultants.in/wp-json/wp/v2/enquiry',
              newRecord,
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            );
            window.alert("We have received your request. Our team will get back to you shortly");
            setForm({
                name: '',
                email: '',
                phone: '',
                message: ''
            })
            setSubmitting(false);
          } catch (error) {
            window.alert("Something went wrong. Could you try again?");
            setSubmitting(false);
        }
    };
    const submitResponse = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!Form.name || Form.name.length <= 0){
            window.alert("Name is a mandatory field");
            return;
        }
        // const recaptchaToken = await grecaptchaObject.execute(siteKey, { action: 'submit' });
        const recaptchaToken = captchaRef.current.getValue();
        captchaRef.current.reset();
        if (!isRecaptchaChecked){
            window.alert("Please check the reCAPTCHA checkbox.");
            return;
        }
        saveForm(recaptchaToken);
    }
      
    return (
        <>
            <form method="post" onSubmit={submitResponse} id="contact-form" className="default-form"> 
                <div className="row clearfix">
                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <input type="text" name="name" onChange={handleChange} value={Form.name} placeholder="Your Name *" required="" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <input type="text" name="phone" required="" onChange={handleChange} value={Form.phone} placeholder="Phone" />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                        <input type="email" name="email" onChange={handleChange} value={Form.email} placeholder="Email Address" />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                        <textarea name="message" onChange={handleChange} value={Form.message} placeholder="Type Your Message..."></textarea>
                    </div>
                    <ReCAPTCHA sitekey={siteKey} ref={captchaRef} onChange={() => setIsRecaptchaChecked(true)} />
                    <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn centred mt-3">
                        <button className="theme-btn-one" type="submit" name="submit-form" disabled={Submitting}>{Submitting ? "Please Wait..." : "Send Message"}</button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default connect(null, {})(ContactForm);