import React from "react";
import PageTitle from "../../components/PageTitle";
import TestimonialSlider from "../../components/testimonials/TestimonialSlider";
import ClientSliderWithCTA from "../../components/ClientSliderWithCTA";
import services from "../../data/services.json";
import ListWidget from "../../components/services/ListWidget";
import { connect } from "react-redux";
import { update_page_meta } from "../../actions/page/header";
const service = services.find(item => item.id === 2);

const OrganizationalMapping = ({ update_page_meta }) => {
    update_page_meta({
        title: `${service.title} | Aquarius HR Consultants`,
        description: service.smallDescription
    })
    return (
        <>
            <PageTitle title={service.title} description={service.smallDescription} breadcrumbs={[{title: "Home", url: "/"}, {title: "Services", url: "/services/"}, {title: "Organizational Mapping"}]} />

            <section class="service-details">
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-lg-8 col-md-12 col-sm-12 content-side">
                            <div class="service-details-content">
                                <div class="content-one">
                                    <div class="sec-title">
                                        {/* <span class="top-title">temporary Staffing</span> */}
                                        <h2>{service.title}</h2>
                                    </div>
                                    {/* <figure class="image-box"><img src="assets/images/service/details-1.jpg" alt="" /></figure> */}
                                    <div class="text">
                                        <p>
                                            The organizational mapping service provided by our firm offers a comprehensive view of the talent landscape, enabling 
                                            organizations to make informed decisions regarding their hiring strategies. Here are the key elements and benefits of 
                                            our mapping service:
                                        </p>
                                        <ul>
                                            <li>
                                                <h4>Holistic View of Talent Landscape</h4>
                                                <p>Our mapping service provides a holistic view of the current talent landscape. We utilize in-depth market intelligence and conduct a confidential analysis of talent pools to assess the suitability of available talent. This enables organizations to understand the talent market, identify potential candidates, and gauge the availability and quality of talent before making hiring decisions.</p>
                                            </li>
                                            <li>
                                                <h4>Functional, Business Unit, and Sectoral Analysis</h4>
                                                <p>We offer coverage of various dimensions, including functional areas, business units, and sectors. Our mapping service provides detailed analyses of talent within these specific areas, allowing clients to gain insights into the talent pool's composition, strengths, and areas of expertise. This information helps organizations align their hiring strategies with the specific talent requirements of their industry and functional needs.</p>
                                            </li>
                                            <li>
                                                <h4>Market Insight for Compensation</h4>
                                                <p>By understanding what competitors are paying for similar jobs, skills, and locations, organizations can gain valuable market insights. This information helps them assess the external competitiveness of their compensation packages, including fixed and variable pay components. It also allows organizations to review and adjust their compensation and employee benefits policies to attract and retain top talent.</p>
                                            </li>
                                            <li>
                                                <h4>Neutral Reference for Analysis</h4>
                                                <p>Our mapping service provides a neutral reference point that supports any analysis conducted independently by organizations. This unbiased perspective helps organizations validate their own findings and decisions, providing an additional layer of confidence and credibility.</p>
                                            </li>
                                            <li>
                                                <h4>Market Analysis for Success Assessment</h4>
                                                <p>Our mapping service also provides market analysis and information to assess the likelihood of success in a full search process. By understanding the talent landscape, organizations can evaluate the feasibility and potential outcomes of their search efforts. This information helps organizations make informed decisions and allocate resources effectively.</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                            <div class="service-sidebar" style={{overflow: "auto"}}>
                                <div class="sidebar-widget categories-widget" style={{position: "sticky", top: 0}}>
                                    <ListWidget activeId={2} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <TestimonialSlider />
            <ClientSliderWithCTA />
        </>
    )
}

export default connect(null, { update_page_meta })(OrganizationalMapping);