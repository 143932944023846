import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import testimonials from '../../data/testimonials.json';
import { truncate } from "../../utils/text";
import { Link } from "react-router-dom";

const TestimonialSlider = ({}) => {
    
    return (
        <section className="testimonial-section bg-color-2">
            <div className="auto-container">
                <div className="sec-title">
                    <span className="top-title">Find out how our clients have benefited from partnering with us</span>
                    <h2>Client Testimonials</h2>
                </div>
                <Swiper
                    className="testimonial-carousel"
                    modules={[Autoplay]}
                    loop={true}
                    speed={1000}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false
                    }}
                    spaceBetween={30}
                    slidesPerView={1}
                    breakpoints={{
                        0: {
                            slidesPerView: 1
                        },
                        480: {
                            slidesPerView: 1
                        },
                        600: {
                            slidesPerView: 1
                        },
                        800: {
                            slidesPerView: 2
                        },
                        1200: {
                            slidesPerView: 2
                        }
                    }}
                    >
                        {
                            testimonials.map(item => {
                                return(
                                    <SwiperSlide key={item.id}>
                                    <div className="testimonial-block-one">
                                        <div className="inner-box">
                                            <div className="border-shap" style={{backgroundImage: `url(${require('../../assets/images/shape/border-1.png')})`}}></div>
                                            <figure className="quote-box"><img src={require("../../assets/images/icons/quote-1.png")} alt="" /></figure>
                                            <div className="author-box">
                                                {/* <figure className="author-thumb"><img src="assets/images/resource/testimonial-1.png" alt=""></figure> */}
                                                {/* <ul className="rating clearfix">
                                                    <li><i className="flaticon-star"></i></li>
                                                    <li><i className="flaticon-star"></i></li>
                                                    <li><i className="flaticon-star"></i></li>
                                                    <li><i className="flaticon-star"></i></li>
                                                    <li><i className="flaticon-star"></i></li>
                                                </ul> */}
                                                <h3>{item?.name}{item?.designation?.length > 0 && <span>, {item?.designation}</span>}</h3>
                                                {item?.organization.length > 0 && <p>{item?.organization}</p>}
                                            </div>
                                            <div className="text">
                                                <p>{truncate(item?.testimonial, 200)}</p>
                                                {/* <p>{item?.testimonial}</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                </Swiper>
            </div>
        </section>
    )
}

export default connect(null, {})(TestimonialSlider);