import React from "react";
import { Route, Routes } from 'react-router-dom';
import Home from "../pages/Home";
import About from "../pages/About";
import ManagementTeam from "../pages/ManagementTeam";
import Services from "../pages/services/Services";
import Industries from "../pages/services/Industries";
import FunctionalRoles from "../pages/services/FunctionalRoles";
import Contact from "../pages/Contact";
import PrivacyPolicy from "../pages/PrivacyPolicy";
// import Sitemap from "../pages/Sitemap";
// import HireForm from "../pages/HireForm";
// import ApplyForm from "../pages/ApplyForm";
import Disclaimer from "../pages/Disclaimer";
import ExecutiveSearchService from "../pages/services/ExecutiveSearchService";
import OrganizationalMapping from "../pages/services/OrganizationalMapping";
import ExecutiveAssessment from "../pages/services/ExecutiveAssessment";
import PageNotFound from "../pages/PageNotFound";
import Compliance from "../pages/Compliance";

const BrowserRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about/" element={<About />} />
                <Route path="/about/management-team/" element={<ManagementTeam />} />
                <Route path="/services/" element={<Services />} />
                <Route path="/services/industries/" element={<Industries />} />
                <Route path="/services/functional-roles/" element={<FunctionalRoles />} />
                <Route path="/services/executive-search-services/" element={<ExecutiveSearchService />} />
                <Route path="/services/organizational-mapping/" element={<OrganizationalMapping />} />
                <Route path="/services/executive-assessment/" element={<ExecutiveAssessment />} />
                <Route path="/contact/" element={<Contact />} />
                <Route path="/privacy-policy/" element={<PrivacyPolicy />} />
                <Route path="/disclaimer/" element={<Disclaimer />} />
                {/* <Route path="/sitemap/" element={<Sitemap />} /> */}
                <Route path="/compliance/" element={<Compliance />} />
                {/* <Route path="/start-hiring/" element={<HireForm />} />
                <Route path="/apply-for-job/" element={<ApplyForm />} /> */}
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </>
    )
}

export default BrowserRoutes;