import React from "react";
import { connect } from "react-redux";
import PageTitle from "../components/PageTitle";
import { addressHtml, email, mailto, officeaddressHtml, phone, telLink } from "../constants/contact";
import ClientSlider from "../components/ClientSlider";
import ContactForm from "../components/ContactForm";
import { update_page_meta } from "../actions/page/header";

const Contact = ({ update_page_meta }) => {
    update_page_meta({
        title: "Get In Touch | Aquarius HR Consultants",
        description: "Contact Aquarius HR Consultants, a renowned executive search firm, to explore how our services can meet your organization's executive talent needs. Our experienced team of HR professionals specializes in identifying and attracting top-level executives who can drive your company's success. Whether you're looking to fill a C-suite position or a critical leadership role, we are here to assist you. Get in touch with Aquarius HR Consultants today and let us help you find the perfect executive talent to elevate your organization to new heights."
    })
    return (
        <>
            <PageTitle title="Get In Touch" description="" breadcrumbs={[{title: 'Home', url: "/"}, {title: "Get In Touch"}]} />

            <section className="contactinfo-section contact-page-section">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-5 col-md-12 col-sm-12 content-column">
                            <div id="content_block_6">
                                <div className="content-box">
                                    <div className="sec-title">
                                        <span className="top-title">Reach out to us</span>
                                        <h2>Communication details</h2>
                                        {/* <p>Visit our <span>Employer FAQ</span> or <span>Employee FAQ</span> page for answers to common questions.</p> */}
                                    </div>
                                    <ul className="info-list clearfix">
                                        {/* <li>
                                            <figure className="icon-box"><img src={require("../assets/images/icons/icon-39.png")} alt="" /></figure>
                                            <div className="inner">
                                                <h4>Location</h4>
                                                {officeaddressHtml}
                                            </div>
                                        </li> */}
                                        <li>
                                            <figure className="icon-box"><img src={require("../assets/images/icons/icon-40.png")} alt="" /></figure>
                                            <div className="inner">
                                                <h4>Call or Email</h4>
                                                <p>
                                                    <a href={telLink}>{phone}</a><br />
                                                    <a href={mailto}>{email}</a>
                                                </p>
                                            </div>
                                        </li>
                                        {/* <li>
                                            <figure className="icon-box"><img src={require("../assets/images/icons/icon-41.png")} alt="" /></figure>
                                            <div className="inner">
                                                <h4>Office Hrs</h4>
                                                <p>Mon - Sat: 9.00am to 7.00pm<br />Sunday: Closed</p>
                                            </div>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12 form-column">
                            <div className="form-inner">
                                <h2>Ready to Connect? Write to us!</h2>
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ClientSlider className="alternet-2" />

            <section className="locations-section bg-color-2">
                <div className="pattern-layer" style={{backgroundImage: `url(${require("../assets/images/shape/pattern-50.png")})`}}></div>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                            <div className="single-item wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="inner-box">
                                    <div className="upper-box">
                                        <h3>Registered Office</h3>
                                        {addressHtml}
                                    </div>
                                    {/* <ul className="info-list clearfix">
                                        <li>
                                            <i className="flaticon-email"></i>
                                            <p>Email</p>
                                            <a href={mailto}>{email}</a>
                                        </li>
                                        <li>
                                            <i className="flaticon-phone-call"></i>
                                            <p>Phone</p>
                                            <a href={telLink}>{phone}</a>
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                            <div className="single-item wow fadeInUp animated animated" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="inner-box">
                                    <div className="upper-box">
                                        <h3>Gurugram</h3>
                                        {officeaddressHtml}
                                    </div>
                                    {/* <ul className="info-list clearfix">
                                        <li>
                                            <i className="flaticon-email"></i>
                                            <p>Email</p>
                                            <a href={mailto}>{email}</a>
                                        </li>
                                        <li>
                                            <i className="flaticon-phone-call"></i>
                                            <p>Phone</p>
                                            <a href={telLink}>{phone}</a>
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </>
    )
}

export default connect(null, { update_page_meta })(Contact);