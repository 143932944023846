import React, { useEffect, useState } from 'react';
import '../assets/css/googlefonts.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/font-awesome-all.css';
import '../assets/css/flaticon.css';
// import '../assets/css/owl.css';
// import '../assets/css/jquery.fancybox.min.css';
import '../assets/css/animate.css';
import '../assets/css/color.css';
import '../assets/css/style.css';
import '../assets/css/responsive.css';
import 'swiper/css/bundle';
import { connect } from 'react-redux';
import TopHeader from '../components/TopHeader';
import BottomHeader from '../components/BottomHeader';
import Footer from '../components/Footer';
import StickyHeader from '../components/StickyHeader';
import MobileMenu from '../components/MobileMenu';
import useScrollPosition from '../hooks/useScrollPosition';

const Layout = ({ children }) => {
    const [fixedHeader, setFixedHeader] = useState('');
    const scrollPosition = useScrollPosition();
    useEffect(() => {
        if (scrollPosition >= 110){
            setFixedHeader('fixed-header');
        }
        else{
            setFixedHeader('');
        }
    }, [scrollPosition]);
    return (
        <>
            <header className={`main-header ${fixedHeader}`}>
                {/* <TopHeader /> */}
                <BottomHeader />
                <StickyHeader />
            </header>
            <MobileMenu />
            
            {children}
            <Footer />
        </>
    )
}

export default connect(null, {})(Layout);