import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import services from '../../data/services.json';
import { Link } from "react-router-dom";

const ServiceSection = ({}) => {
    return (
        <section className="service-section">
            <div className="pattern-layer" style={{backgroundImage: `url(${require('../../assets/images/shape/pattern-6.png')})`}}></div>
            <div className="anim-icon">
                <div className="icon-1" style={{backgroundImage: `url(${require('../../assets/images/icons/anim-icon-1.png')})`}}></div>
                <div className="icon-2"></div>
            </div>
            <div className="auto-container">
                <div className="sec-title centred">
                    <span className="top-title">Range of Services</span>
                    <h2>Our Comprehensive Offerings</h2>
                    <p>Designed to support our clients in attracting and selecting top executive talent, gaining market insights, and making informed hiring decisions to drive organizational success</p>
                </div>
                <Swiper
                    className="three-item-carousel"
                    modules={[Autoplay]}
                    loop={true}
                    autoplay={{
                        delay: 1000,
                        disableOnInteraction: false
                    }}
                    spaceBetween={30}
                    slidesPerView={1}
                    breakpoints={{
                        0: {
                            slidesPerView: 1
                        },
                        480: {
                            slidesPerView: 1
                        },
                        600: {
                            slidesPerView: 2
                        },
                        800: {
                            slidesPerView: 2
                        },
                        1024: {
                            slidesPerView: 3
                        }
                    }}
                    >
                        {
                            services.map(service => {
                                return (
                                    <SwiperSlide key={service.id}>
                                        <div className="service-block-one">
                                            <div className="inner-box">
                                                <figure className="image-box"><img src={require(`../../assets/images/service/${service.imgSmall}`)} alt={service.title} /></figure>
                                                <div className="lower-content">
                                                    <div className="content-box">
                                                        <div className="inner">
                                                            <figure className="icon-box"><img src={require(`../../assets/images/icons/${service.icon}`)} alt="" /></figure>
                                                            <h4>{service.title}</h4>
                                                        </div>
                                                        <div className="link"><Link to={service.uri}>More Details</Link></div>
                                                    </div>
                                                    <div className="overlay-content">
                                                        <p>{service.smallDescription}</p>
                                                        <Link to={service.uri} service={service}><i className="flaticon-right-arrow"></i>More details</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                </Swiper>
            </div>
        </section>
    )
}

export default ServiceSection;