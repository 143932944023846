import { configureStore } from '@reduxjs/toolkit'

import { pagemetaReducer } from './reducers/pagemetaReducer';

const reducer = {
  page: pagemetaReducer,
}

const store = configureStore({
  reducer: reducer
})

export default store;