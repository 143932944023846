import React from "react";
import { connect } from "react-redux";
import { usePageMeta } from "../reducers/pagemetaReducer";

const Head = ({}) => {
    const pageMeta = usePageMeta();
    document.title = pageMeta.title;
    document.querySelector('meta[name="description"]').setAttribute("content", pageMeta.description);
    return (
        <></>
    )
}

export default connect(null, {})(Head);