import React, { useState } from "react";
import { Link } from "react-router-dom";

const WelcomeSection = ({}) => {
    const [activeTab, setActiveTab] = useState(1);
    return (
        <section className="welcome-section">
            <div className="auto-container">
                <div className="sec-title centred">
                    <span className="top-title">Welcome to Aquarius HR Consultants Pvt. Ltd. (AHCPL)</span>
                    <h2>Your Dynamic Executive Talent Acquisition Partner</h2>
                    <p>We are the preferred executive search firm for leading organizations in India and multinational corporations operating in the Indian market</p>
                </div>
                <div className="row clearfix">
                    <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                        <figure className="image-box js-tilt"><img src={require("../../assets/images/resource/welcome.webp")} alt="" /></figure>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div id="content_block_1">
                            <div className="content-box">
                                <div className="tabs-box">
                                    {/* <div className="tab-btn-box">
                                        <ul className="tab-btns tab-buttons clearfix">
                                            <li className={`tab-btn ${activeTab === 1 ? 'active-btn' : ''}`} onClick={() => setActiveTab(1)}>
                                                <i className="employ-icon flaticon-businessman"></i>
                                                <h5>Organizations</h5>
                                                <i className="arrow-icon flaticon-up-arrow-2"></i>
                                            </li>
                                            <li className={`tab-btn ${activeTab === 2 ? 'active-btn' : ''}`} onClick={() => setActiveTab(2)}>
                                                <i className="employ-icon flaticon-employer"></i>
                                                <h5>Executives</h5>
                                                <i className="arrow-icon flaticon-up-arrow-2"></i>
                                            </li>
                                        </ul>
                                    </div> */}
                                    <div className="tabs-content">
                                        {activeTab === 1 ? 
                                        <div className={`tab ${activeTab === 1 ? 'active-tab' : ''}`}>
                                            <div className="inner-box">
                                                {/* <h5>Find the Employees</h5> */}
                                                <h2>Discover Your Next Executive on AHCPL</h2>
                                                <p>
                                                    AHCPL is your premier destination for hiring top executive talent. Whether you're seeking a visionary CEO, 
                                                    a strategic CFO, or a dynamic executive leader, AHCPL has the resources and expertise to find the perfect 
                                                    match for your organization. 
                                                </p>
                                                <ul className="list clearfix">
                                                    <li>
                                                        <figure className="icon-box"><img src={require("../../assets/images/icons/icon-1.png")} alt="" /></figure>
                                                        <h4>Extensive Executive Network</h4>
                                                    </li>
                                                    <li>
                                                        <figure className="icon-box"><img src={require("../../assets/images/icons/icon-2.png")} alt="" /></figure>
                                                        <h4>Streamlined Executive Search Process</h4>
                                                    </li>
                                                </ul>
                                                {/* <div className="link"><Link to="/start-hiring/"><i className="flaticon-right-arrow"></i>Start Discovering Your Next Executive !</Link></div> */}
                                            </div>
                                        </div> :
                                        <div className={`tab ${activeTab === 2 ? 'active-tab' : ''}`}>
                                            <div className="inner-box">
                                                <h2>Propel Your Career to New Heights with Aquarius</h2>
                                                <p> 
                                                    Whether you're an experienced executive looking for your next challenge or a rising professional aspiring to lead, 
                                                    Aquarius provides the resources and opportunities to help you thrive in leadership positions. 
                                                </p>
                                                <ul className="list clearfix">
                                                    <li>
                                                        <figure className="icon-box"><img src={require("../../assets/images/icons/icon-71.png")} alt="" /></figure>
                                                        <h4>Diverse Leadership Opportunities</h4>
                                                    </li>
                                                    <li>
                                                        <figure className="icon-box"><img src={require("../../assets/images/icons/icon-72.png")} alt="" /></figure>
                                                        <h4>Exclusive Access to Top Organizations</h4>
                                                    </li>
                                                </ul>
                                                <div className="link"><Link to="/apply-for-job/"><i className="flaticon-right-arrow"></i>Apply for Your Next Leadership Role !</Link></div>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WelcomeSection;