import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MenuContent from "./MenuContent";

const BottomHeader = ({}) => {
    const handleToggler = (e) => {
        let body = document.body;
        if (body.classList.contains('mobile-menu-visible')){
            body.classList.remove('mobile-menu-visible');
        }
        else{
            body.classList.add('mobile-menu-visible');
        }
    }
    return (
        <div className="header-lower">
                <div className="outer-box clearfix">
                    <div className="menu-area pull-left clearfix">
                        {/* <figure className="logo-box"><Link to="/"><img src={require("../assets/images/logo.png")} style={{width: "250px"}} alt="" /></Link></figure> */}
                        <figure className="logo-box"><Link to="/"><img src={require("../assets/images/2.webp")} style={{width: "250px"}} alt="" /></Link></figure>
                        <div className="mobile-nav-toggler" onClick={handleToggler}>
                            <i className="icon-bar"></i>
                            <i className="icon-bar"></i>
                            <i className="icon-bar"></i>
                        </div>
                        <nav className="main-menu navbar-expand-md navbar-light">
                            <MenuContent />
                        </nav>
                    </div>
                    <ul className="menu-right-content pull-right clearfix">
                        {/* <li>
                            <div className="search-btn">
                                <button type="button" className="search-toggler"><i className="flaticon-loupe-1"></i></button>
                            </div>
                        </li>
                        <li>
                            <div className="language">
                                <div className="lang-btn">
                                    <i className="icon flaticon-planet-earth"></i>
                                    <span className="txt">EN</span>
                                    <span className="arrow fa fa-angle-down"></span>
                                </div>
                                <div className="lang-dropdown">
                                    <ul>
                                        <li><a href="index.html">German</a></li>
                                        <li><a href="index.html">Italian</a></li>
                                        <li><a href="index.html">Chinese</a></li>
                                        <li><a href="index.html">Russian</a></li>
                                    </ul>
                                </div>
                            </div>
                        </li> */}
                        <li>
                            <Link to="/contact/" className="theme-btn-one">Contact Us</Link>
                            {/* <Link to="/start-hiring/" className="theme-btn-one">Start Hiring</Link> */}
                            {/* <Link to="/apply-for-job/" className="ms-2 theme-btn-two">Apply for Job</Link> */}
                        </li>
                    </ul>
                </div>
            </div>

    )
}

export default connect(null, {})(BottomHeader);