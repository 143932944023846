import React from "react";
import { connect } from "react-redux";
import Banner from "../components/home/Banner";
import ClientSlider from "../components/ClientSlider";
import TestimonialSlider from "../components/testimonials/TestimonialSlider";
import WelcomeSection from "../components/home/WelcomeSection";
import AboutSection from "../components/home/AboutSection";
import ServiceSection from "../components/home/ServiceSection";
import RecruitmentTechnologies from "../components/home/RecruitmentTechnologies";
import IndustriesSection from "../components/industries/IndustriesSection";
import { update_page_meta } from "../actions/page/header";

const Home = ({ update_page_meta }) => {
    update_page_meta({
        title: "Premier Executive Search Firm | Find Top-Level Talent | Aquarius HR Consultants",
        description: "We are preferred executive search partners to leading Indian & Multinationals in India. Was founded in the year 2010 empanelled with various organizations through a customized recruitment process. Our team, equipped with technology and domain knowledge, understands the requirements completely and sources the candidates with the right skill-sets, aptitude, attitude, and above all cultural fits for organizations."
    })
    return (
        <>
            <Banner />
            <ClientSlider />
            <WelcomeSection />
            <AboutSection />
            <ServiceSection />
            {/* <RecruitmentTechnologies /> */}
            <IndustriesSection />
            <TestimonialSlider />
        </>
    )
}

export default connect(null, { update_page_meta })(Home);