import React from "react";
import services from '../../data/services.json';
import { Link } from "react-router-dom";

const ListWidget = ({ activeId }) => {
    return (
        <ul class="categories-list clearfix">
            {
                services.map((item, index) => {
                    return (
                        <li key={index}><Link to={item.uri} className={item.id === activeId ? "active" : ''}>{item.title}</Link></li>
                    )
                })
            }
        </ul>
    )
}

export default ListWidget;