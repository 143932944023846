import React from "react";
import PageTitle from "../../components/PageTitle";
import ClientSliderWithCTA from "../../components/ClientSliderWithCTA";
import TestimonialSlider from "../../components/testimonials/TestimonialSlider";
import services from "../../data/services.json";
import ListWidget from "../../components/services/ListWidget";
import { connect } from "react-redux";
import { update_page_meta } from "../../actions/page/header";
const service = services.find(item => item.id === 3);

const ExecutiveAssessment = ({ update_page_meta }) => {
    update_page_meta({
        title: `${service.title} | Aquarius HR Consultants`,
        description: service.smallDescription
    })
    return (
        <>
            <PageTitle title={service.title} description={service.smallDescription} breadcrumbs={[{title: "Home", url: "/"}, {title: "Services", url: "/services/"}, {title: "Executive Assessment"}]} />
            
            <section class="service-details">
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-lg-8 col-md-12 col-sm-12 content-side">
                            <div class="service-details-content">
                                <div class="content-one">
                                    <div class="sec-title">
                                        {/* <span class="top-title">temporary Staffing</span> */}
                                        <h2>{service.title}</h2>
                                    </div>
                                    {/* <figure class="image-box"><img src="assets/images/service/details-1.jpg" alt="" /></figure> */}
                                    <div class="text">
                                        <p>
                                            Our comprehensive approach to executive assessment employs multiple methods to evaluate an individual’s ability to perform, fit and make an enduring impact in a critical leadership role. We offer a comprehensive assessment providing in-depth data and insights to inform selection decisions for senior leadership roles, primarily the CEO, CFO or other CXO positions. The finalist assessment provides a complete picture of candidates’ relevant business and functional expertise, marketplace and industry knowledge, leadership abilities, character and motivation, cultural fit and capacity to grow and change with the job. We provide insight into how candidates are likely to behave, make decisions and operate in the role, and identify potential risks or derailers that can have implications for long-term success in the context of the organization, culture, and market, increasing confidence in selection decisions
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                            <div class="service-sidebar" style={{overflow: "auto"}}>
                                <div class="sidebar-widget categories-widget" style={{position: "sticky", top: 0}}>
                                    <ListWidget activeId={3} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TestimonialSlider />
            <ClientSliderWithCTA />
        </>
    )
}

export default connect(null, { update_page_meta })(ExecutiveAssessment);